<template>
    <div
        :class="
            item.opened
                ? (item.lessons &&
                      item.lessons.length &&
                      item.blockProgress === item.blockCountLessons) ||
                  item.completeSurveys ||
                  item.passedEntranceTest
                    ? 'dashboardProgressBoxLayout dashboardProgressBoxLayout--finished'
                    : 'dashboardProgressBoxLayout'
                : 'dashboardProgressBoxLayout'
        "
    >
        <p
            v-if="item.subtitle"
            :class="
                item.opened
                    ? (item.lessons &&
                          item.lessons.length &&
                          item.blockProgress === item.blockCountLessons) ||
                      item.completeSurveys ||
                      item.passedEntranceTest
                        ? 'boxText boxText--green dashboardProgressSubtitle'
                        : 'boxText boxText--grey3 dashboardProgressSubtitle'
                    : 'boxText boxText--grey3 dashboardProgressSubtitle'
            "
        >
            {{ item.subtitle }}
        </p>

        <div
            v-else
            :class="
                item.opened
                    ? (item.lessons &&
                          item.lessons.length &&
                          item.blockProgress === item.blockCountLessons) ||
                      item.completeSurveys ||
                      item.passedEntranceTest
                        ? 'dashboardProgressBox dashboardProgressBox--finished'
                        : 'dashboardProgressBox'
                    : 'dashboardProgressBox'
            "
        >
            <div
                class="dashboardProgressBoxHeader"
                @click="() => (this.isSublistActive = !this.isSublistActive)"
            >
                <div class="dashboardProgressBoxItem">
                    <div class="dashboardProgressBoxItem__icon">
                        <img
                            v-if="!item.opened"
                            src="@/assets/img/common/lock.svg"
                            alt="lock"
                            class="imgBlock dashboardProgressBoxItem__toggle_img"
                        />
                        <div v-else>
                            <img
                                v-if="(item.lessons && (item.lessons.length === item.lessons.filter(lesson => lesson.isPassed).length)) || item.completeSurveys"
                                src="@/assets/img/pages/study/done.svg"
                                alt="circle"
                                class="imgBlock dashboardProgressBoxItem__toggle_img"
                            />
                            <p v-else-if="item.passedEntranceTest">
                                <img
                                    v-if="
                                        this.$route.fullPath ===
                                            '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true' ||
                                            this.isCurrentStatus === 'banned' ||
                                            this.isCurrentStatus === 'blocked'
                                    "
                                    src="@/assets/img/pages/study/done.svg"
                                    alt="circle"
                                    class="imgBlock dashboardProgressBoxItem__toggle_img"
                                />
                                <Tooltip
                                    v-else
                                    class="tooltipIcon"
                                    :tooltipIcon="
                                        require('@/assets/img/pages/study/done.svg')
                                    "
                                    tooltipDirection="right"
                                    text="Вы уже сдали вступительный тест☺️ После окончания обучения и сдачи финального теста - Вы сможете сравнить их результаты😉"
                                />
                            </p>
                            <img
                                v-else
                                src="@/assets/img/common/circleInactive.svg"
                                alt="circle"
                                class="imgBlock dashboardProgressBoxItem__toggle_img"
                            />
                        </div>
                    </div>
                    <div
                        :class="
                            item.notClickable && !item.link
                                ? 'dashboardProgressBoxSublist__text dashboardProgressBoxSublist__text--notClickable'
                                : 'dashboardProgressBoxSublist__text'
                        "
                        @click="
                            () => {
                                if (item.link) $router.push(item.link);
                            }
                        "
                    >
                        <p
                            v-if="item.blockPriority"
                            :class="
                                `text2 dashboardProgressBoxItem__block ${
                                    item.opened ? 'colorLynch' : 'colorHeather'
                                }`
                            "
                        >
                            Блок {{ item.blockPriority }}
                        </p>
                        <div class="dashboardProgressBoxItem__titleLayout">
                            <p
                                v-if="item.blockTitle"
                                :class="
                                    `dashboardProgressBoxItem__title ${
                                        item.opened ? '' : 'colorGullGray'
                                    }`
                                "
                            >
                                {{ item.blockTitle }}
                            </p>
                            &nbsp;
                            <VipUpsellButton
                                v-if="!item.hasAccesByAccesLevel"
                                tooltipType="small"
                            />
                        </div>
                    </div>
                    <div
                        v-if="item.lessons && item.lessons.length"
                        :class="
                            this.isSublistActive
                                ? 'dashboardProgressBoxItem__toggle dashboardProgressBoxItem__toggle--active'
                                : 'dashboardProgressBoxItem__toggle'
                        "
                    >
                        <img
                            src="@/assets/img/common/arrowToggle.svg"
                            alt="circle"
                            class="imgBlock dashboardProgressBoxItem__toggle_img"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="item.lessons && item.lessons.length"
                :class="
                    this.isSublistActive
                        ? 'dashboardProgressBoxSublist'
                        : 'dashboardProgressBoxSublist hidden'
                "
            >
                <div
                    v-for="(subitem, subindex) in item.lessons"
                    :key="subindex"
                    class="dashboardProgressBoxItem dashboardProgressBoxItem--sm"
                >
                    <div class="dashboardProgressBoxItem__icon">
                        <img
                            v-if="!item.opened || !subitem.hasAccesToLesson"
                            src="@/assets/img/common/lock.svg"
                            alt="lock"
                            class="imgBlock dashboardProgressBoxItem__toggle_img"
                        />
                        <div v-else>
                            <img
                                v-if="subitem.isPassed"
                                src="@/assets/img/pages/study/done.svg"
                                alt="circle"
                                class="imgBlock dashboardProgressBoxItem__toggle_img"
                            />
                            <img
                                v-else
                                src="@/assets/img/common/circleInactive.svg"
                                alt="circle"
                                class="imgBlock dashboardProgressBoxItem__toggle_img"
                            />
                        </div>
                    </div>
                    <div
                        class="dashboardProgressBoxSublist__text"
                        @click="
                            () =>
                                onRedirectTo(
                                    item.opened &&
                                        subitem.hasAccesToLesson &&
                                        item.blockPriority &&
                                        subitem.lesson &&
                                        subitem.lesson.lesson_id
                                        ? `/${$store.getters.getMember.data.slug}/study/${item.blockPriority}/${subitem.lesson.lesson_id}`
                                        : ``
                                )
                        "
                    >
                        <p
                            v-if="subitem.lesson.lesson_number"
                            class="title1 colorLynch dashboardProgressBoxItem__block"
                        >
                            Урок {{ subitem.lesson.lesson_number }}
                        </p>
                        <div class="dashboardProgressBoxItem__titleLayout">
                            <p
                                v-if="subitem.lesson.title"
                                class="text2 colorFiord dashboardProgressBoxItem__title"
                            >
                                {{ subitem.lesson.title }}
                            </p>
                            &nbsp;
                            <VipUpsellButton
                                v-if="!subitem.hasAccesByAccesLevel"
                                tooltipType="small"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from "@/views/components/Tooltip/Tooltip";
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";

export default {
    name: "DashboardProgressBox",

    components: {
        Tooltip,
        VipUpsellButton,
    },

    props: ["item"],

    data() {
        return {
            isSublistActive: false,
        };
    },

    computed: {
        isCurrentStatus() {
            return (
                this.$store.getters.getDashboardActivityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member
                    .status
            );
        },
    },

    methods: {
        onRedirectTo(route) {
            if (route) this.$router.push(route);
        },
    },
};
</script>

<style lang="scss">
@import "DashboardProgressBox.scss";
</style>
