var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layout"},[_c('div',{staticClass:"popup popup--2"},[_c('div',{staticClass:"targetSelection"},[_c('button',{staticClass:"popup__close",attrs:{"type":"button"},on:{"click":_vm.onClose}}),_c('p',{staticClass:"h2 targetSelection__title"},[_vm._v(" Выбрать цель ")]),_c('p',[_vm._v(" Выберите одну программу, на которую Вы будете копить баллы и которая будет Вас мотивировать. ")]),_c('div',{staticClass:"targetSelection__contentItem"},[_c('div',{staticClass:"radioClearGroup"},_vm._l((_vm.goal),function(item,index){return _c('label',{key:index},[_c('div',{class:("inputItem radioNoBorder\n                                " + (item._id === _vm.selectedGoal._id
                                        ? ' radioNoBorder--success'
                                        : item._id === _vm.pickedItem._id
                                        ? ' radioNoBorder--picked'
                                        : ''))},[_c('div',{staticClass:"targetInput"},[_c('img',{class:item._id === _vm.selectedGoal._id
                                            ? 'radioNoBorder__selectionIcon'
                                            : item._id === _vm.pickedItem._id
                                            ? 'hidden'
                                            : 'hidden',attrs:{"alt":"done","src":require("@/assets/img/pages/study/done.svg")}}),_c('input',{staticClass:"input--checkbox",attrs:{"type":"radio","name":"first"},on:{"click":function () { return _vm.handleClickButton(item._id); }}}),_c('div',{staticClass:"colorMirage targetInputText"},[_c('span',[_vm._v(" "+_vm._s(item.name))]),_c('span',{class:("text3 text3--extraBold " + (item._id === _vm.selectedGoal._id
                                                    ? 'colorGreen'
                                                    : item._id === _vm.pickedItem._id &&
                                                      'colorDenim'))},[_vm._v(_vm._s(item.points)+" баллов")])])]),_c('a',{staticClass:"colorDenim targetInputLink",attrs:{"href":("" + (item.detailsLink)),"target":"_blank","rel":"nofollow"}},[_vm._v(" Подробнее об этом продукте ")])])])}),0)]),_c('div',{staticClass:"targetSelection__buttons"},[_c('Button',{attrs:{"title":"отмена","extraClass":"button--white","onClick":function () { return _vm.onClose(); }}}),_c('Button',{attrs:{"title":"выбрать","extraClass":"button","onClick":function () { return _vm.chooseGoal(); }}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }