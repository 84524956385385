var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.item.opened
            ? (_vm.item.lessons &&
                  _vm.item.lessons.length &&
                  _vm.item.blockProgress === _vm.item.blockCountLessons) ||
              _vm.item.completeSurveys ||
              _vm.item.passedEntranceTest
                ? 'dashboardProgressBoxLayout dashboardProgressBoxLayout--finished'
                : 'dashboardProgressBoxLayout'
            : 'dashboardProgressBoxLayout'},[(_vm.item.subtitle)?_c('p',{class:_vm.item.opened
                ? (_vm.item.lessons &&
                      _vm.item.lessons.length &&
                      _vm.item.blockProgress === _vm.item.blockCountLessons) ||
                  _vm.item.completeSurveys ||
                  _vm.item.passedEntranceTest
                    ? 'boxText boxText--green dashboardProgressSubtitle'
                    : 'boxText boxText--grey3 dashboardProgressSubtitle'
                : 'boxText boxText--grey3 dashboardProgressSubtitle'},[_vm._v(" "+_vm._s(_vm.item.subtitle)+" ")]):_c('div',{class:_vm.item.opened
                ? (_vm.item.lessons &&
                      _vm.item.lessons.length &&
                      _vm.item.blockProgress === _vm.item.blockCountLessons) ||
                  _vm.item.completeSurveys ||
                  _vm.item.passedEntranceTest
                    ? 'dashboardProgressBox dashboardProgressBox--finished'
                    : 'dashboardProgressBox'
                : 'dashboardProgressBox'},[_c('div',{staticClass:"dashboardProgressBoxHeader",on:{"click":function () { return (this$1.isSublistActive = !this$1.isSublistActive); }}},[_c('div',{staticClass:"dashboardProgressBoxItem"},[_c('div',{staticClass:"dashboardProgressBoxItem__icon"},[(!_vm.item.opened)?_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/common/lock.svg"),"alt":"lock"}}):_c('div',[((_vm.item.lessons && (_vm.item.lessons.length === _vm.item.lessons.filter(function (lesson) { return lesson.isPassed; }).length)) || _vm.item.completeSurveys)?_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/pages/study/done.svg"),"alt":"circle"}}):(_vm.item.passedEntranceTest)?_c('p',[(
                                    this.$route.fullPath ===
                                        '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true' ||
                                        this.isCurrentStatus === 'banned' ||
                                        this.isCurrentStatus === 'blocked'
                                )?_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/pages/study/done.svg"),"alt":"circle"}}):_c('Tooltip',{staticClass:"tooltipIcon",attrs:{"tooltipIcon":require('@/assets/img/pages/study/done.svg'),"tooltipDirection":"right","text":"Вы уже сдали вступительный тест☺️ После окончания обучения и сдачи финального теста - Вы сможете сравнить их результаты😉"}})],1):_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/common/circleInactive.svg"),"alt":"circle"}})])]),_c('div',{class:_vm.item.notClickable && !_vm.item.link
                            ? 'dashboardProgressBoxSublist__text dashboardProgressBoxSublist__text--notClickable'
                            : 'dashboardProgressBoxSublist__text',on:{"click":function () {
                            if (_vm.item.link) { _vm.$router.push(_vm.item.link); }
                        }}},[(_vm.item.blockPriority)?_c('p',{class:("text2 dashboardProgressBoxItem__block " + (_vm.item.opened ? 'colorLynch' : 'colorHeather'))},[_vm._v(" Блок "+_vm._s(_vm.item.blockPriority)+" ")]):_vm._e(),_c('div',{staticClass:"dashboardProgressBoxItem__titleLayout"},[(_vm.item.blockTitle)?_c('p',{class:("dashboardProgressBoxItem__title " + (_vm.item.opened ? '' : 'colorGullGray'))},[_vm._v(" "+_vm._s(_vm.item.blockTitle)+" ")]):_vm._e(),_vm._v("   "),(!_vm.item.hasAccesByAccesLevel)?_c('VipUpsellButton',{attrs:{"tooltipType":"small"}}):_vm._e()],1)]),(_vm.item.lessons && _vm.item.lessons.length)?_c('div',{class:this.isSublistActive
                            ? 'dashboardProgressBoxItem__toggle dashboardProgressBoxItem__toggle--active'
                            : 'dashboardProgressBoxItem__toggle'},[_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/common/arrowToggle.svg"),"alt":"circle"}})]):_vm._e()])]),(_vm.item.lessons && _vm.item.lessons.length)?_c('div',{class:this.isSublistActive
                    ? 'dashboardProgressBoxSublist'
                    : 'dashboardProgressBoxSublist hidden'},_vm._l((_vm.item.lessons),function(subitem,subindex){return _c('div',{key:subindex,staticClass:"dashboardProgressBoxItem dashboardProgressBoxItem--sm"},[_c('div',{staticClass:"dashboardProgressBoxItem__icon"},[(!_vm.item.opened || !subitem.hasAccesToLesson)?_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/common/lock.svg"),"alt":"lock"}}):_c('div',[(subitem.isPassed)?_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/pages/study/done.svg"),"alt":"circle"}}):_c('img',{staticClass:"imgBlock dashboardProgressBoxItem__toggle_img",attrs:{"src":require("@/assets/img/common/circleInactive.svg"),"alt":"circle"}})])]),_c('div',{staticClass:"dashboardProgressBoxSublist__text",on:{"click":function () { return _vm.onRedirectTo(
                                _vm.item.opened &&
                                    subitem.hasAccesToLesson &&
                                    _vm.item.blockPriority &&
                                    subitem.lesson &&
                                    subitem.lesson.lesson_id
                                    ? ("/" + (_vm.$store.getters.getMember.data.slug) + "/study/" + (_vm.item.blockPriority) + "/" + (subitem.lesson.lesson_id))
                                    : ""
                            ); }}},[(subitem.lesson.lesson_number)?_c('p',{staticClass:"title1 colorLynch dashboardProgressBoxItem__block"},[_vm._v(" Урок "+_vm._s(subitem.lesson.lesson_number)+" ")]):_vm._e(),_c('div',{staticClass:"dashboardProgressBoxItem__titleLayout"},[(subitem.lesson.title)?_c('p',{staticClass:"text2 colorFiord dashboardProgressBoxItem__title"},[_vm._v(" "+_vm._s(subitem.lesson.title)+" ")]):_vm._e(),_vm._v("   "),(!subitem.hasAccesByAccesLevel)?_c('VipUpsellButton',{attrs:{"tooltipType":"small"}}):_vm._e()],1)])])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }