<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent pageContent--3 dashboard">
                <p class="pageTitle">Дашборд</p>

                <div class="dashboardRow">
                    <div class="dashboardCol">
                        <div class="dashboardProgressDummyCard">
                            <div
                                class="popup-layout"
                                v-if="
                                    isVisibleModalDashboardOnboarding ||
                                        this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard
                                "
                            ></div>
                            <OnboardModal
                                v-if="
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisibleProgressOnboarding) ||
                                        (this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard &&
                                            isVisibleProgressOnboardingReview)
                                "
                                :isActiveSteps="true"
                                currentStep="1"
                                totalSteps="5"
                                title="Общий прогресс"
                                description="Здесь показан Ваш путь по программы и прогресс прохождения."
                                :isNextButton="true"
                                nextButtonTitle="ДАЛЕЕ"
                                :onClose="() => showedPointsOnboarding()"
                                :onCloseModal="() => showedDashboardOnboarding()"
                            />
                            <div
                                :class="
                                    `dashboardCard dashboardProgressCard ${
                                        (isVisibleModalDashboardOnboarding &&
                                            isVisibleProgressOnboarding) ||
                                        (this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard &&
                                            isVisibleProgressOnboardingReview) ||
                                        this.isCurrentStatus === 'banned' ||
                                        this.isCurrentStatus === 'blocked'
                                            ? 'dashboardProgressOnboarding'
                                            : ''
                                    }`
                                "
                            >
                                <div class="dashboardCardHeader dashboardCardHeader--3">
                                    <p class="h4 dashboardCardHeader__title">
                                        Общий прогресс
                                    </p>
                                    <Tooltip
                                        v-if="this.isCurrentStatus === 'banned'"
                                        text="В момент блокировки другие разделы личного кабинета недоступны, но после разблокировки Вы с лёгкостью сможете продолжить обучение"
                                    />
                                    <Tooltip
                                        v-if="this.isCurrentStatus === 'blocked'"
                                        text="В момент заморозки другие разделы личного кабинета недоступны, но после ее окончания Вы с лёгкостью сможете продолжить обучение"
                                    />
                                </div>
                                <div class="customScroll dashboardCardContent">
                                    <b-card
                                        v-if="
                                            !(
                                                this.$store.getters.getUserProgress &&
                                                this.$store.getters.getUserProgress
                                                    .progress &&
                                                this.$store.getters.getUserProgress
                                                    .progress.data &&
                                                this.$store.getters.getUserProgress
                                                    .progress.data.length &&
                                                this.$store.getters.getUserProgress
                                                    .progress.data[0] &&
                                                this.$store.getters.getUserProgress
                                                    .progress.data[0].blocks
                                            )
                                        "
                                    >
                                        <b-skeleton
                                            type="input"
                                            class="skeletonInput"
                                            width="95%"
                                            height="50px"
                                        ></b-skeleton>
                                        <b-skeleton
                                            type="input"
                                            class="skeletonInput"
                                            width="95%"
                                            height="50px"
                                        ></b-skeleton>
                                        <b-skeleton
                                            type="input"
                                            class="skeletonInput"
                                            width="95%"
                                            height="50px"
                                        ></b-skeleton>
                                        <b-skeleton
                                            type="input"
                                            class="skeletonInput"
                                            width="95%"
                                            height="50px"
                                        ></b-skeleton>
                                    </b-card>
                                    <div class="dashboardProgress" v-else>
                                        <DashboardProgressBox
                                            v-for="(item, index) in newProgressList"
                                            :key="index"
                                            :item="item"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashboardCard">
                            <div class="dashboardCardHeader dashboardCardHeader--2">
                                <p class="h4 dashboardCardHeader__title">Мой методист</p>
                            </div>
                            <div class="dashboardCardContent">
                                <div class="dashboardMethodist">
                                    <div class="dashboardMethodistInfo">
                                        <div class="dashboardMethodistInfo__content">
                                            <div class="dashboardMethodistInfo__main">
                                                <div
                                                    class="dashboardMethodistInfo__main_left"
                                                >
                                                    <p
                                                        class="dashboardMethodistInfo__name"
                                                        v-if="!isLoadingMethodistData"
                                                    >
                                                        {{
                                                            methodistData &&
                                                                methodistData.name
                                                        }}
                                                    </p>

                                                    <b-skeleton
                                                        v-else
                                                        width="50%"
                                                        height="24px"
                                                    ></b-skeleton>

                                                    <p
                                                        class="dashboardMethodistInfo__phone"
                                                    >
                                                        <a
                                                            v-if="!isLoadingMethodistData"
                                                            style="color: black"
                                                            :href="
                                                                `tel:${methodistData &&
                                                                    methodistData.phone}`
                                                            "
                                                        >
                                                            {{
                                                                methodistData &&
                                                                    methodistData.phone
                                                            }}
                                                        </a>

                                                        <b-skeleton
                                                            v-else
                                                            width="55%"
                                                            height="19px"
                                                        ></b-skeleton>
                                                    </p>
                                                    <p
                                                        class="dashboardMethodistInfo__phone"
                                                    >
                                                        <a
                                                            v-if="!isLoadingMethodistData"
                                                            style="color: black"
                                                            :href="
                                                                `tel:${methodistData &&
                                                                    methodistData.second_phone}`
                                                            "
                                                        >
                                                            {{
                                                                methodistData &&
                                                                    methodistData.second_phone
                                                            }}
                                                        </a>

                                                        <b-skeleton
                                                            v-else
                                                            width="55%"
                                                            height="19px"
                                                        ></b-skeleton>
                                                    </p>
                                                </div>
                                                <div
                                                    v-if="!isLoadingMethodistData"
                                                    class="dashboardMethodistInfo__main_right"
                                                >
                                                    <img
                                                        v-if="
                                                            methodistData &&
                                                                methodistData.photo
                                                        "
                                                        :src="
                                                            `${$oldClientTestUrl.slice(
                                                                0,
                                                                -1
                                                            ) + methodistData.photo}`
                                                        "
                                                        alt="methodist"
                                                    />

                                                    <img
                                                        v-if="
                                                            !methodistData &&
                                                                !methodistData.photo
                                                        "
                                                        src="@/assets/img/common/defaultAvatar.svg"
                                                        alt="methodist"
                                                    />
                                                </div>
                                                <b-skeleton
                                                    v-else
                                                    type="avatar"
                                                ></b-skeleton>
                                            </div>
                                            <a
                                                v-if="!isLoadingMethodistData"
                                                :href="
                                                    `mailto:${methodistData &&
                                                        methodistData.email}`
                                                "
                                                target="_blank"
                                                class="text2 colorDenim dashboardMethodistInfo__mail"
                                            >
                                                {{ methodistData && methodistData.email }}
                                            </a>

                                            <b-skeleton
                                                v-else
                                                width="60%"
                                                height="17px"
                                            ></b-skeleton>
                                        </div>
                                    </div>
                                    <div class="dashboardMethodistContent">
                                        <p
                                            class="title1 dashboardMethodistContent__timeTitle"
                                        >
                                            Время работы
                                        </p>
                                        <p
                                            class="text2 dashboardMethodistContent__time"
                                            v-if="!isLoadingMethodistData"
                                        >
                                            {{
                                                methodistData &&
                                                    methodistData.working_time
                                            }}
                                        </p>
                                        <p v-else>
                                            <b-skeleton width="25%"></b-skeleton>
                                        </p>

                                        <p
                                            class="text2 colorGray dashboardMethodistContent__text"
                                            v-if="!isLoadingMethodistData"
                                        >
                                            {{
                                                methodistData && methodistData.description
                                            }}
                                        </p>
                                        <p
                                            class="text2 colorGray dashboardMethodistContent__text"
                                            v-else
                                        >
                                            <b-skeleton></b-skeleton>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            :class="
                                `dashboardCard ${
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisibleAchivementsOnboarding) ||
                                    (this.$store.getters.getIsReviewOnboard
                                        .isReviewOnboard &&
                                        isVisibleAchivementsOnboardingReview) ||
                                    this.isCurrentStatus === 'banned' ||
                                    this.isCurrentStatus === 'blocked'
                                        ? 'dashboardAchivementsOnboarding'
                                        : ''
                                }`
                            "
                        >
                            <OnboardModal
                                class="achivementsOnboardingModal"
                                v-if="
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisibleAchivementsOnboarding) ||
                                        (this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard &&
                                            isVisibleAchivementsOnboardingReview)
                                "
                                :isActiveSteps="true"
                                currentStep="3"
                                totalSteps="5"
                                title="Достижения"
                                description="Здесь будет отображаться информация о Ваших достижениях."
                                :isNextButton="true"
                                nextButtonTitle="ДАЛЕЕ"
                                :isBackButton="true"
                                :onBackButton="() => backToPointsOnboarding()"
                                :onClose="() => showedRatingOnboarding()"
                                :onCloseModal="() => showedDashboardOnboarding()"
                            />
                            <Tooltip
                                v-if="this.isCurrentStatus === 'banned'"
                                text="В момент блокировки другие разделы личного кабинета недоступны, но после разблокировки Вы с лёгкостью сможете продолжить обучение"
                            />
                            <Tooltip
                                v-if="this.isCurrentStatus === 'blocked'"
                                text="В момент заморозки другие разделы личного кабинета недоступны, но после ее окончания Вы с лёгкостью сможете продолжить обучение"
                            />
                            <div class="dashboardCardHeader">
                                <p class="h4 dashboardCardHeader__title">
                                    Достижения
                                </p>
                                <b-skeleton
                                    type="button"
                                    v-if="isLoadingAchivementsData"
                                    width="150px"
                                    height="20px"
                                ></b-skeleton>
                                <router-link
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/dashboard/achievements'
                                    "
                                    class="btn-text dashboardCardHeader__btn"
                                    v-else
                                    >посмотреть все</router-link
                                >
                            </div>
                            <div class="dashboardCardContent">
                                <div class="achievementsList">
                                    <div
                                        v-if="isLoadingAchivementsData"
                                        class="achievementsListLoading"
                                    >
                                        <div>
                                            <b-skeleton
                                                type="avatar"
                                                width="64px"
                                                height="64px"
                                            ></b-skeleton>
                                            <b-skeleton
                                                height="20px"
                                                style="margin-top: 16px"
                                            ></b-skeleton>
                                        </div>
                                        <div>
                                            <b-skeleton
                                                type="avatar"
                                                width="64px"
                                                height="64px"
                                            ></b-skeleton>
                                            <b-skeleton
                                                height="20px"
                                                style="margin-top: 16px"
                                            ></b-skeleton>
                                        </div>
                                        <div>
                                            <b-skeleton
                                                type="avatar"
                                                width="64px"
                                                height="64px"
                                            ></b-skeleton>
                                            <b-skeleton
                                                height="20px"
                                                style="margin-top: 16px"
                                            ></b-skeleton>
                                        </div>
                                        <div>
                                            <b-skeleton
                                                type="avatar"
                                                width="64px"
                                                height="64px"
                                            ></b-skeleton>
                                            <b-skeleton
                                                height="20px"
                                                style="margin-top: 16px"
                                            ></b-skeleton>
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        v-for="(item, index) in newAchievementsList"
                                        :key="index"
                                        class="achievementsItem"
                                    >
                                        <div v-if="item.isActive !== true">
                                            <img
                                                v-if="
                                                    $route.fullPath ===
                                                        '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true' ||
                                                        isVisibleModalDashboardOnboarding ||
                                                        isCurrentStatus === 'banned' ||
                                                        isCurrentStatus === 'blocked'
                                                "
                                                class="achievementsIcon achievementsItem__icon"
                                                src="@/assets/img/common/achievements/achievementsLock.svg"
                                                alt="achievementsLock"
                                            />
                                            <Tooltip
                                                v-else
                                                class="achievementsIcon achievementsItem__icon"
                                                :tooltipIcon="
                                                    require('@/assets/img/common/achievements/achievementsLock.svg')
                                                "
                                                :boxDistance="8"
                                                text='К сожалению, это достижение пока закрыто для Вас. Нажмите на кнопку "Посмотреть все", чтобы узнать что нужно сделать для открытия этого достижения.'
                                            />
                                        </div>
                                        <img
                                            v-else
                                            class="achievementsIcon achievementsItem__icon"
                                            :src="
                                                `${$oldClientTestUrl.slice(0, -1) +
                                                    item.icon}`
                                            "
                                            alt="achievement"
                                        />

                                        <p class="alignCenter h5 achievementsItem__title">
                                            <span>{{ item.name }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboardCol">
                        <div
                            :class="
                                `dashboardCard ${
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisiblePackagesOnboarding) ||
                                    (this.$store.getters.getIsReviewOnboard
                                        .isReviewOnboard &&
                                        isVisiblePackagesOnboardingReview)
                                        ? 'dashboardPackagesOnboarding'
                                        : ''
                                }`
                            "
                        >
                            <OnboardModal
                                class="packagesOnboardingModal"
                                v-if="
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisiblePackagesOnboarding) ||
                                        (this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard &&
                                            isVisiblePackagesOnboardingReview)
                                "
                                :isActiveSteps="true"
                                currentStep="5"
                                totalSteps="5"
                                title="Пакет участия"
                                description="Здесь будет отображаться информация о Вашем текущем пакете и специальных предложениях.
                                Так же Вы сможете увидеть информацию о других пакетах участия в программе Трейдинг от А до Я 3.5."
                                :isNextButton="true"
                                nextButtonTitle="ЗАВЕРШИТЬ"
                                :isBackButton="true"
                                :onBackButton="() => backToRatingOnboarding()"
                                :onClose="() => showedDashboardOnboarding()"
                                :onCloseModal="() => showedDashboardOnboarding()"
                            />
                            <div class="dashboardCardHeader">
                                <p class="h4 dashboardCardHeader__title">
                                    Пакеты участия (мой и другие)
                                </p>
                                <b-skeleton
                                    type="button"
                                    width="25%"
                                    height="20px"
                                    v-if="isLoadingPackagesData"
                                ></b-skeleton>
                                <router-link
                                    v-else
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/dashboard/packages'
                                    "
                                    class="btn-text dashboardCardHeader__btn"
                                    >посмотреть все</router-link
                                >
                            </div>
                            <div class="dashboardCardContent">
                                <div class="dashboardPackage">
                                    <div class="dashboardPackageCurrent">
                                        <b-card v-if="isLoadingPackagesData">
                                            <b-skeleton type="input"></b-skeleton>
                                            <b-skeleton
                                                style="margin-top: 10px"
                                                width="100px"
                                            ></b-skeleton>
                                        </b-card>

                                        <p
                                            class="h4 alignCenter dashboardPackageCurrent__name"
                                            v-if="!isLoadingPackagesData"
                                        >
                                            {{ activeCourseName }}
                                        </p>
                                        <p
                                            :class="
                                                `dashboardPackageCurrent__status ${
                                                    courseStatus === 'active'
                                                        ? 'boxText boxText--green'
                                                        : courseStatus === 'blocked'
                                                        ? 'boxText boxText--orange'
                                                        : courseStatus === 'banned'
                                                        ? 'boxText boxText--red'
                                                        : 'boxText'
                                                }`
                                            "
                                            v-if="!isLoadingPackagesData"
                                        >
                                            {{
                                                courseStatus === "active"
                                                    ? "Активный"
                                                    : courseStatus === "blocked"
                                                    ? "Заморожен"
                                                    : courseStatus === "banned"
                                                    ? "Заблокирован"
                                                    : "Неактивный"
                                            }}
                                        </p>
                                    </div>
                                    <div class="dashboardPackageContent">
                                        <Banners />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            :class="
                                `dashboardCard ${
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisiblePointsOnboarding) ||
                                    (this.$store.getters.getIsReviewOnboard
                                        .isReviewOnboard &&
                                        isVisiblePointsOnboardingReview) ||
                                    this.isCurrentStatus === 'banned' ||
                                    this.isCurrentStatus === 'blocked'
                                        ? 'dashboardPointsOnboarding'
                                        : ''
                                }`
                            "
                        >
                            <OnboardModal
                                class="ratingOnboardingModal"
                                v-if="
                                    (isVisibleModalDashboardOnboarding &&
                                        isVisiblePointsOnboarding) ||
                                        (this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard &&
                                            isVisiblePointsOnboardingReview)
                                "
                                :isActiveSteps="true"
                                currentStep="2"
                                totalSteps="5"
                                title="Мои баллы"
                                description="Здесь будет отображаться информация о заработанных Вами баллах и Вашей следующей цели."
                                :isNextButton="true"
                                nextButtonTitle="ДАЛЕЕ"
                                :isBackButton="true"
                                :onBackButton="() => backToProgressOnboarding()"
                                :onClose="() => showedAchivementsOnboarding()"
                                :onCloseModal="() => showedDashboardOnboarding()"
                            />

                            <Tooltip
                                v-if="this.isCurrentStatus === 'banned'"
                                text="В момент блокировки другие разделы личного кабинета недоступны, но после разблокировки Вы с лёгкостью сможете продолжить обучение"
                            />
                            <Tooltip
                                v-if="this.isCurrentStatus === 'blocked'"
                                text="В момент заморозки другие разделы личного кабинета недоступны, но после ее окончания Вы с лёгкостью сможете продолжить обучение"
                            />
                            <div class="dashboardCardHeader">
                                <p class="h4 dashboardCardHeader__title">
                                    Мои баллы &nbsp;
                                    <Tooltip
                                        v-if="!isVisibleModalDashboardOnboarding"
                                        :tooltipIcon="
                                            require('@/assets/img/common/info.svg')
                                        "
                                        text="Баллы начисляются за различные действия на платформе (за какие именно Вы можете посмотреть, нажав на количество Ваших баллов ниже). ВАЖНО: у начисленных баллов ограниченный срок использования, посмотреть сколько дней осталось до сгорания Вы можете нажав на кнопку «Потратить баллы»"
                                    />
                                </p>
                                <b-skeleton
                                    type="button"
                                    width="35%"
                                    height="20px"
                                    v-if="isLoadingRatingData && isLoadingGoalData"
                                ></b-skeleton>
                                <router-link
                                    v-else
                                    :to="
                                        '/' + this.$route.params.slug + '/dashboard/shop'
                                    "
                                    class="btn-text dashboardCardHeader__btn"
                                    >{{
                                        myPointsCoin >= this.minCostItem
                                            ? "Потратить баллы"
                                            : "Перейти в магазин"
                                    }}</router-link
                                >
                            </div>
                            <div class="dashboardCardContent">
                                <div class="dashboardScore">
                                    <div class="dashboardScoreTotal">
                                        <b-skeleton
                                            type="button"
                                            v-if="isLoadingRatingData"
                                            width="120px"
                                        ></b-skeleton>
                                        <p
                                            v-if="myPointsCoin && !isLoadingRatingData"
                                            class="dashboardScoreTotal__number"
                                        >
                                            {{ myPointsCoin }}
                                        </p>
                                        <p
                                            v-if="myPointsCoin && !isLoadingRatingData"
                                            class="link mediumWeight alignCenter dashboardScoreTotal__hint"
                                            @click="onOpenModalEarningPoints()"
                                        >
                                            Как заработать баллы?
                                        </p>
                                    </div>

                                    <b-card
                                        v-if="isLoadingRatingData && isLoadingGoalData"
                                        style="width: 100%"
                                    >
                                        <b-skeleton
                                            width="50%"
                                            height="16px"
                                        ></b-skeleton>
                                        <b-skeleton
                                            type="input"
                                            style="margin-top: 10px"
                                            width="70%"
                                            height="20px"
                                        ></b-skeleton>
                                        <b-skeleton
                                            type="button"
                                            style="margin-top: 10px"
                                            width="40%"
                                            height="44px"
                                        ></b-skeleton>
                                    </b-card>

                                    <div class="dashboardScoreContent" v-else>
                                        <p class="title1 dashboardScoreContent__title">
                                            Моя цель:
                                        </p>
                                        <p class="text2 dashboardScoreContent__text">
                                            {{
                                                isCurrentGoal.name ||
                                                    "Вы ещё не выбрали свою цель"
                                            }}
                                        </p>
                                        <div
                                            class="dashboardScoreProgress"
                                            v-if="isCurrentGoal.name"
                                        >
                                            <p
                                                class="text2 dashboardScoreProgress__title"
                                            >
                                                Накоплено:
                                                <span
                                                    v-if="myPointsCoin"
                                                    :class="
                                                        myPointsCoin >=
                                                        isCurrentGoal.points
                                                            ? 'colorForestGreen'
                                                            : 'colorRed'
                                                    "
                                                >
                                                    {{ myPointsCoin }}
                                                    из
                                                    {{ isCurrentGoal.points }}
                                                </span>
                                            </p>
                                            <div
                                                class="progressRail progressRail--lg dashboardScoreProgress__rail"
                                            >
                                                <div
                                                    :style="`width:${progressRail}`"
                                                    :class="
                                                        myPointsCoin >=
                                                        isCurrentGoal.points
                                                            ? 'progressRail__total progressRail__total--green'
                                                            : 'progressRail__total'
                                                    "
                                                ></div>
                                            </div>
                                            <!-- <progress
                                                :value="myScore"
                                                :max="isCurrentGoal.points"
                                            /> -->
                                        </div>
                                        <button
                                            v-if="isCurrentGoal.name"
                                            class="btn-text colorYellow dashboardScoreContent__btn2"
                                            @click="onOpenModalTargetSelection()"
                                        >
                                            Изменить цель
                                        </button>
                                        <Button
                                            v-if="!isCurrentGoal.name"
                                            class="dashboardScoreContent__btn"
                                            title="выбрать цель"
                                            extraClass="button--bordered"
                                            :onClick="() => onOpenModalTargetSelection()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                v-if="
                                    this.$store.getters.getDashboardRating &&
                                        this.$store.getters.getDashboardRating.rating &&
                                        this.$store.getters.getDashboardRating.rating
                                            .fiveFavorites
                                "
                                :class="
                                    `dashboardCard ${
                                        (isVisibleModalDashboardOnboarding &&
                                            isVisibleRatingOnboarding) ||
                                        (this.$store.getters.getIsReviewOnboard
                                            .isReviewOnboard &&
                                            isVisibleRatingOnboardingReview) ||
                                        this.isCurrentStatus === 'banned' ||
                                        this.isCurrentStatus === 'blocked'
                                            ? 'dashboardRatingOnboarding'
                                            : ''
                                    }`
                                "
                                style="min-height: 275px"
                            >
                                <Tooltip
                                    v-if="this.isCurrentStatus === 'banned'"
                                    text="В момент блокировки другие разделы личного кабинета недоступны, но после разблокировки Вы с лёгкостью сможете продолжить обучение"
                                />
                                <Tooltip
                                    v-if="this.isCurrentStatus === 'blocked'"
                                    text="В момент заморозки другие разделы личного кабинета недоступны, но после ее окончания Вы с лёгкостью сможете продолжить обучение"
                                />
                                <div class="dashboardCardHeader">
                                    <OnboardModal
                                        class="pointsOnboardingModal"
                                        v-if="
                                            (isVisibleModalDashboardOnboarding &&
                                                isVisibleRatingOnboarding) ||
                                                (this.$store.getters.getIsReviewOnboard
                                                    .isReviewOnboard &&
                                                    isVisibleRatingOnboardingReview)
                                        "
                                        :isActiveSteps="true"
                                        currentStep="4"
                                        totalSteps="5"
                                        title="Рейтинг"
                                        description="Здесь будет отображаться информация о Вашем рейтинге."
                                        :isNextButton="true"
                                        nextButtonTitle="ДАЛЕЕ"
                                        :isBackButton="true"
                                        :onBackButton="
                                            () => backToAchivementsOnboarding()
                                        "
                                        :onClose="() => showedPackagesOnboarding()"
                                        :onCloseModal="() => showedDashboardOnboarding()"
                                    />
                                    <p class="h4 dashboardCardHeader__title">Рейтинг</p>
                                    <b-skeleton
                                        type="button"
                                        width="30%"
                                        height="20px"
                                        v-if="isLoadingRatingData"
                                    ></b-skeleton>
                                    <button
                                        v-else
                                        @click="() => toggleRating()"
                                        class="btn-text dashboardCardHeader__btn"
                                    >
                                        {{ ratingBtnText }}
                                    </button>
                                </div>

                                <b-skeleton-table
                                    v-if="isLoadingRatingData"
                                    :rows="3"
                                    :columns="3"
                                    :table-props="{
                                        bordered: true,
                                        striped: true,
                                    }"
                                ></b-skeleton-table>

                                <div class="dashboardCardContent" v-else>
                                    <div v-if="ratingTableActive" class="dashboardRating">
                                        <div class="dashboardRatingHead">
                                            <div class="dashboardRatingRow">
                                                <div class="dashboardRatingCol">
                                                    <p class="title1">Позиция</p>
                                                </div>
                                                <div class="dashboardRatingCol">
                                                    <p class="title1">Студент</p>
                                                </div>
                                                <div class="dashboardRatingCol">
                                                    <p
                                                        class="title1 link link--noUnderline link--orange"
                                                        @click="onOpenModalPoints()"
                                                    >
                                                        <b>Набранные баллы</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dashboardRatingBody">
                                            <div
                                                v-for="(item, index) in this.$store
                                                    .getters.getDashboardRating.rating
                                                    .fiveFavorites"
                                                :key="index"
                                                :class="
                                                    item.isCurrentUser
                                                        ? 'dashboardRatingRow dashboardRatingRow--current'
                                                        : 'dashboardRatingRow'
                                                "
                                            >
                                                <div class="dashboardRatingCol">
                                                    <p
                                                        v-if="item.position === 1"
                                                        class="text2 colorWhite alignCenter dashboardRatingCol__position dashboardRatingCol__position--first"
                                                    >
                                                        <span
                                                            class="dashboardRatingCol__position_inner"
                                                            >{{ item.position }}</span
                                                        >
                                                    </p>
                                                    <p
                                                        v-else-if="item.position === 2"
                                                        class="text2 colorWhite alignCenter dashboardRatingCol__position dashboardRatingCol__position--second"
                                                    >
                                                        <span
                                                            class="dashboardRatingCol__position_inner"
                                                            >{{ item.position }}</span
                                                        >
                                                    </p>
                                                    <p
                                                        v-else-if="item.position === 3"
                                                        class="text2 colorWhite alignCenter dashboardRatingCol__position dashboardRatingCol__position--third"
                                                    >
                                                        <span
                                                            class="dashboardRatingCol__position_inner"
                                                            >{{ item.position }}</span
                                                        >
                                                    </p>
                                                    <p
                                                        v-else
                                                        class="text2 colorGray alignCenter dashboardRatingCol__position"
                                                    >
                                                        <span
                                                            class="dashboardRatingCol__position_inner"
                                                            >{{ item.position }}</span
                                                        >
                                                    </p>
                                                </div>
                                                <div class="dashboardRatingCol">
                                                    <p
                                                        class="text2 dashboardRatingCol__name"
                                                    >
                                                        <img
                                                            v-if="item.user.photo"
                                                            :src="
                                                                `${$apiUrl.slice(0, -1) +
                                                                    item.user.photo}`
                                                            "
                                                            alt="avatar"
                                                        />
                                                        <img
                                                            v-else-if="!item.avatar"
                                                            src="@/assets/img/common/defaultAvatar.svg"
                                                            alt="avatar"
                                                        />
                                                        {{
                                                            `${item.user.firstName} ${item.user.lastName}`
                                                        }}
                                                    </p>
                                                </div>
                                                <div class="dashboardRatingCol">
                                                    <p
                                                        class="h4 colorGray dashboardRatingCol__score"
                                                    >
                                                        {{ item.points || "0" }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else class="dashboardRatingChart">
                                        <apexchart
                                            class="apexChart"
                                            height="179"
                                            type="bar"
                                            dir="ltr"
                                            :series="[
                                                {
                                                    name: 'Рейтинг',
                                                    data: apexchartData,
                                                },
                                            ]"
                                            :options="ratingChart.chartOptions"
                                        ></apexchart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <TargetSelection
            v-if="isVisibleModalTargetSelection"
            :onClose="() => onCloseModalTargetSelection()"
        />
        <EarningPointsPopup
            v-if="isVisibleModalEarningPoints"
            :onClose="() => onCloseModalEarningPoints()"
        />
        <PointsPopup v-if="isVisibleModalPoints" :onClose="() => onCloseModalPoints()" />
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import Button from "@/views/components/Button/Button";
import Tooltip from "@/views/components/Tooltip/Tooltip";
import DashboardProgressBox from "@/views/components/DashboardProgressBox/DashboardProgressBox";
import Banners from "@/views/components/Banners/Banners";

import TargetSelection from "@/views/pages/dashboard/targetSelection/targetSelection";
import EarningPointsPopup from "@/views/pages/dashboard/dashboardShop/earningPointsPopup/earningPointsPopup";
import PointsPopup from "@/views/pages/dashboard/pointsPopup/pointsPopup";

import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

export default {
    name: "dashboard",
    components: {
        Layout,
        SideBar,
        Button,
        Tooltip,
        DashboardProgressBox,
        Banners,

        TargetSelection,
        EarningPointsPopup,
        PointsPopup,

        OnboardModal,
    },

    async beforeMount() {
        // this.updateMemberConfirmationsStore();
        // this.updateMemberStore();
        // this.updateProgressStore();
        this.updateDashboardRatingStore();
        this.updateDashboardActivityStatus();
        this.updateDashboardMethodist();
        this.updateEarningPoints();
        this.updateDashboardShop();
        this.updateDashboardAchievement();
        this.checkIfFilledProfile();

        this.updateDashboardGoal();
    },

    watch: {
        "$route.params.slug": function() {
            // this.updateMemberStore();
            // this.updateMemberConfirmationsStore();
            // this.updateProgressStore();
            this.updateDashboardRatingStore();
            this.updateDashboardActivityStatus();
            this.updateDashboardMethodist();
            this.updateEarningPoints();
            this.updateDashboardGoal();
            this.updateDashboardShop();
            this.updateDashboardAchievement();
            this.checkIfFilledProfile();
        },
    },

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        confirmations: function() {
            return (
                (this.$store.getters.getConfirmations &&
                    this.$store.getters.getConfirmations.data) ||
                {}
            );
        },

        apiURL: function() {
            return process.env.VUE_APP_API_URL;
        },

        apexchartData() {
            const avgScore = Math.floor(
                this.$store.getters.getDashboardRating.rating.avgScore
            );
            return [
                this.$store.getters.getDashboardRating.rating.myScore,
                avgScore,
                this.$store.getters.getDashboardRating.rating.bestScore,
            ];
        },

        newAchievementsList() {
            const achievementsList = this.$store.getters.getDashboardAchievement
                .achievement.achievements;

            const sortedByActive = [
                ...achievementsList.filter((item) => item.isActive),
                ...achievementsList.filter((item) => !item.isActive),
            ];

            if (sortedByActive && sortedByActive.length) {
                return [...sortedByActive.slice(0, 4)];
            } else {
                return [];
            }
        },

        isVisibleModalDashboardOnboarding: function() {
            return (
                // this.isLoadedConfirmations &&
                this.confirmations &&
                this.confirmations.viewedWelcomePopup &&
                !(this.member.agreementData && this.member.agreementData.showRules) &&
                this.member &&
                    this.member.passedEntranceTest &&
                    this.member.passedEntranceTest.isPassed &&
                this.confirmations.viewedWelcomeVideo &&
                !this.confirmations.viewedOnBoardingDashboard &&
                this.$route.path === "/trejding_ot_a_do_ya_3_5/dashboard"
            );
        },

        newProgressList() {
            let newList = [
                {
                    subtitle: "Подготовка",
                },
                {
                    blockTitle: "Анкета",
                    completeSurveys:
                        this.$store.getters.getUserProgress &&
                        this.$store.getters.getUserProgress.progress &&
                        this.$store.getters.getUserProgress.progress.data &&
                        this.$store.getters.getUserProgress.progress.data.length &&
                        this.$store.getters.getUserProgress.progress.data[0] &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys
                            .completeIntroSurvey,
                    hasAccesByAccesLevel:
                        this.$store.getters.getUserProgress &&
                        this.$store.getters.getUserProgress.progress &&
                        this.$store.getters.getUserProgress.progress.data &&
                        this.$store.getters.getUserProgress.progress.data.length &&
                        this.$store.getters.getUserProgress.progress.data[0] &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys
                            .hasAccesByAccesLevel,
                    opened:
                        this.$store.getters.getUserProgress &&
                        this.$store.getters.getUserProgress.progress &&
                        this.$store.getters.getUserProgress.progress.data &&
                        this.$store.getters.getUserProgress.progress.data.length &&
                        this.$store.getters.getUserProgress.progress.data[0] &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys
                            .hasAccesByAccesLevel,
                    notClickable: true,
                    link:
                        this.member.quizData &&
                        this.member.quizData.showQuiz &&
                        this.member.quizData.quiz &&
                        this.member.quizData.quiz.trigger === "beforeStart"
                            ? "/" + this.$route.params.slug + "/dashboard/survey"
                            : "",
                },
                {
                    blockTitle: "Вступительный тест",
                    passedEntranceTest:
                        this.member &&
                        this.member.passedEntranceTest &&
                        this.member.passedEntranceTest.isPassed,
                    hasAccesByAccesLevel:
                        this.$store.getters.getUserProgress &&
                        this.$store.getters.getUserProgress.progress &&
                        this.$store.getters.getUserProgress.progress.data &&
                        this.$store.getters.getUserProgress.progress.data.length &&
                        this.$store.getters.getUserProgress.progress.data[0] &&
                        this.$store.getters.getUserProgress.progress.data[0]
                            .passedTests &&
                        this.$store.getters.getUserProgress.progress.data[0].passedTests
                            .hasAccesByAccesLevel,
                    opened:
                        this.$store.getters.getUserProgress &&
                        this.$store.getters.getUserProgress.progress &&
                        this.$store.getters.getUserProgress.progress.data &&
                        this.$store.getters.getUserProgress.progress.data.length &&
                        this.$store.getters.getUserProgress.progress.data[0] &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys &&
                        this.$store.getters.getUserProgress.progress.data[0].surveys
                            .hasAccesByAccesLevel,
                    notClickable: true,
                    link:
                        this.member &&
                        this.member.passedEntranceTest &&
                        !this.member.passedEntranceTest.isPassed
                            ? "/" + this.$route.params.slug + "/dashboard/opening-test"
                            : "",
                },
                {
                    subtitle: "старт",
                },
            ];

            if (
                this.$store.getters.getUserProgress &&
                this.$store.getters.getUserProgress.progress &&
                this.$store.getters.getUserProgress.progress.data &&
                this.$store.getters.getUserProgress.progress.data.length &&
                this.$store.getters.getUserProgress.progress.data[0] &&
                this.$store.getters.getUserProgress.progress.data[0].blocks
            ) {
                newList = [
                    ...newList,
                    ...this.$store.getters.getUserProgress.progress.data[0].blocks,
                ];
            }

            return newList;
        },

        myPointsCoin() {
            return (
                (this.$store.getters.getDashboardRating &&
                    this.$store.getters.getDashboardRating.rating &&
                    this.$store.getters.getDashboardRating.rating.myPointsCoin) ||
                "0"
            );
        },

        progressRail() {
            return (
                (this.$store.getters.getDashboardRating.rating.myPointsCoin * 100) /
                    this.isCurrentGoal.points +
                "%"
            );
        },

        methodistData() {
            return this.$store.getters.getDashboardMethodist.methodist;
        },

        activeCourseName() {
            return this.$store.getters.getDashboardActivityStatus.activityStatus.member
                .accessLevel.name;
        },

        courseStatus() {
            return this.$store.getters.getDashboardActivityStatus.activityStatus.member
                .status;
        },
        isCurrentGoal() {
            return this.$store.getters.getDashboardGoal.goal || "";
        },

        isCurrentStatus() {
            return (
                this.$store.getters.getDashboardActivityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member
                    .status
            );
        },
    },

    data() {
        return {
            minCostItem: null,
            isVisibleModalTargetSelection: false,
            isVisibleModalEarningPoints: false,
            isVisibleModalPoints: false,
            ratingTableActive: true,

            isLoadingMethodistData: false,
            isLoadingProgressData: false,
            isLoadingAchivementsData: false,
            isLoadingRatingData: false,
            isLoadingPackagesData: false,
            isLoadingGoalData: false,

            isLoadedConfirmations: false,

            isVisibleProgressOnboarding: true,
            isVisiblePointsOnboarding: false,
            isVisibleAchivementsOnboarding: false,
            isVisibleRatingOnboarding: false,
            isVisiblePackagesOnboarding: false,

            isReviewOnboard: this.$store.getters.getIsReviewOnboard.isReviewOnboard,
            isVisibleProgressOnboardingReview: true,
            isVisiblePointsOnboardingReview: false,
            isVisibleAchivementsOnboardingReview: false,
            isVisibleRatingOnboardingReview: false,
            isVisiblePackagesOnboardingReview: false,

            ratingBtnText: "смотреть график",

            ratingChart: {
                chartOptions: {
                    chart: {
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "70%",
                            endingShape: "rounded",
                            distributed: true,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    colors: ["#FFAB08", "#FF5B01", "#4FC0E8"],
                    xaxis: {
                        categories: [
                            "МОЙ РЕЗУЛЬТАТ",
                            "СРЕДНИЙ РЕЗУЛЬТАТ",
                            ["ЛУЧШИЙ РЕЗУЛЬТАТ", "ЗА ВСЁ ВРЕМЯ"],
                        ],
                        labels: {
                            align: "right",
                            minHeight: undefined,
                            maxHeight: 40,
                            style: {
                                colors: ["#333F55"],
                                fontSize: "9px",
                                fontFamily: "Lato, sans-serif",
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            align: "right",
                            minWidth: 40,
                            maxWidth: 40,
                            style: {
                                colors: ["#828EA6"],
                                fontSize: "9px",
                                fontFamily: "Lato, sans-serif",
                            },
                        },
                    },
                    grid: {
                        borderColor: "#F5F8FF",
                    },
                    fill: {
                        opacity: 1,
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            formatter: function(val) {
                                return val + " баллов";
                            },
                        },
                    },
                },
            },
        };
    },

    methods: {
        onOpenModalTargetSelection() {
            this.isVisibleModalTargetSelection = true;
        },
        onCloseModalTargetSelection() {
            this.isVisibleModalTargetSelection = false;
        },

        onOpenModalEarningPoints() {
            this.isVisibleModalEarningPoints = true;
        },
        onCloseModalEarningPoints() {
            this.isVisibleModalEarningPoints = false;
        },

        onOpenModalPoints() {
            this.isVisibleModalPoints = true;
        },
        onCloseModalPoints() {
            this.isVisibleModalPoints = false;
        },

        showedPointsOnboarding() {
            this.isVisibleProgressOnboarding = false;
            this.isVisiblePointsOnboarding = true;

            this.isVisibleProgressOnboardingReview = false;
            this.isVisiblePointsOnboardingReview = true;
        },

        backToProgressOnboarding() {
            this.isVisibleProgressOnboarding = true;
            this.isVisiblePointsOnboarding = false;

            this.isVisiblePointsOnboardingReview = false;
            this.isVisibleProgressOnboardingReview = true;
        },

        showedAchivementsOnboarding() {
            this.isVisiblePointsOnboarding = false;
            this.isVisibleAchivementsOnboarding = true;

            this.isVisiblePointsOnboardingReview = false;
            this.isVisibleAchivementsOnboardingReview = true;

            window.scrollTo({
                top: 99999999,
                behavior: "smooth",
            });
        },

        backToPointsOnboarding() {
            this.isVisiblePointsOnboarding = true;
            this.isVisibleAchivementsOnboarding = false;

            this.isVisiblePointsOnboardingReview = true;
            this.isVisibleAchivementsOnboardingReview = false;

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },

        showedRatingOnboarding() {
            this.isVisibleAchivementsOnboarding = false;
            this.isVisibleRatingOnboarding = true;

            this.isVisibleAchivementsOnboardingReview = false;
            this.isVisibleRatingOnboardingReview = true;
        },

        backToAchivementsOnboarding() {
            this.isVisibleAchivementsOnboarding = true;
            this.isVisibleRatingOnboarding = false;

            this.isVisibleAchivementsOnboardingReview = true;
            this.isVisibleRatingOnboardingReview = false;
        },

        showedPackagesOnboarding() {
            this.isVisibleRatingOnboarding = false;
            this.isVisiblePackagesOnboarding = true;

            this.isVisibleRatingOnboardingReview = false;
            this.isVisiblePackagesOnboardingReview = true;

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },

        backToRatingOnboarding() {
            this.isVisibleRatingOnboarding = true;
            this.isVisiblePackagesOnboarding = false;

            this.isVisibleRatingOnboardingReview = true;
            this.isVisiblePackagesOnboardingReview = false;

            window.scrollTo({
                top: 99999999,
                behavior: "smooth",
            });
        },

        toggleRating() {
            this.ratingTableActive = !this.ratingTableActive;
            if (this.ratingTableActive) {
                this.ratingBtnText = "смотреть график";
            } else {
                this.ratingBtnText = "смотреть список";
            }
        },

        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );

            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (getMemberData.data.code === "programs") {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateMemberConfirmationsStore() {
            const getMemberConfirmationsData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/confirmations"
            );
            if (
                getMemberConfirmationsData &&
                getMemberConfirmationsData.data &&
                getMemberConfirmationsData.data.result
            ) {
                await this.$store.dispatch("setConfirmations", {
                    data: getMemberConfirmationsData.data.confirmations,
                });

                this.isLoadedConfirmations = true;
            } else {
                console.error(
                    (getMemberConfirmationsData.data &&
                        getMemberConfirmationsData.data.message) ||
                        "Ошибка при поиске статусов ознакомительных попапов"
                );
                await this.$store.dispatch("clearConfirmations");
            }
        },

        async showedDashboardOnboarding() {
            if (
                this.isVisibleModalDashboardOnboarding &&
                this.$store.getters.getIsReviewOnboard.isReviewOnboard
            ) {
                await this.axios.post(
                    "/programs/" +
                        this.$route.params.slug +
                        "/confirmations?confirmation=viewedOnBoardingDashboard"
                );
                this.updateMemberStore();
                this.updateMemberConfirmationsStore();
                this.isVisiblePackagesOnboarding = false;
                this.$store.dispatch("setIsReviewOnBoard", false);
                await this.$router.push("/" + this.$route.params.slug + "/study");
                this.$store.dispatch("setIsReviewOnBoardStudy", true);
            } else {
                if (this.isVisibleModalDashboardOnboarding) {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingDashboard"
                    );
                    this.updateMemberStore();
                    this.updateMemberConfirmationsStore();
                    this.isVisiblePackagesOnboarding = false;
                } else {
                    this.$store.dispatch("setIsReviewOnBoard", false);
                    await this.$router.push("/" + this.$route.params.slug + "/study");
                    this.$store.dispatch("setIsReviewOnBoardStudy", true);
                }
            }
        },

        async updateProgressStore() {
            this.isLoadingProgressData = true;

            const getProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress"
            );

            if (getProgress && getProgress.data && getProgress.data.result) {
                await this.$store.dispatch("setUserProgress", getProgress.data);
            } else {
                console.error(
                    "Ошибка при поиске прогресса: " + (getProgress.data.error || "")
                );
                await this.$store.dispatch("clearUserProgress");
            }

            this.isLoadingProgressData = false;
        },

        async updateDashboardRatingStore() {
            this.isLoadingRatingData = true;
            const getDashboardRating = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/rating"
            );

            if (
                getDashboardRating &&
                getDashboardRating.data &&
                getDashboardRating.data.result
            ) {
                await this.$store.dispatch(
                    "setDashboardRating",
                    getDashboardRating.data.response
                );
            } else {
                console.error(
                    "Ошибка при поиске рейтинга: " +
                        (getDashboardRating.data.message || "")
                );
                await this.$store.dispatch("clearDashboardRating");
            }

            this.isLoadingRatingData = false;
        },

        async updateDashboardActivityStatus() {
            this.isLoadingPackagesData = true;
            const getDashboardActivityStatus = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/access"
            );

            if (
                getDashboardActivityStatus &&
                getDashboardActivityStatus.data &&
                getDashboardActivityStatus.data.result
            ) {
                await this.$store.dispatch(
                    "setDashboardActivityStatus",
                    getDashboardActivityStatus.data
                );
            } else {
                console.error(
                    "Ошибка при поиске активного статуса: " +
                        (getDashboardActivityStatus.data.message || "")
                );
                await this.$store.dispatch("clearDashboardActivityStatus");
            }

            this.isLoadingPackagesData = false;
        },

        async updateDashboardMethodist() {
            this.isLoadingMethodistData = true;

            const getDashboardMethodist = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/methodist"
            );

            if (
                getDashboardMethodist &&
                getDashboardMethodist.data &&
                getDashboardMethodist.data.result &&
                getDashboardMethodist.data.methodist
            ) {
                await this.$store.dispatch(
                    "setDashboardMethodist",
                    getDashboardMethodist.data.methodist
                );
            } else {
                console.error(
                    "Ошибка при поиске методиста: " +
                        (getDashboardMethodist.data.message || "")
                );
                await this.$store.dispatch("clearDashboardMethodist");
            }
            this.isLoadingMethodistData = false;
        },

        async updateEarningPoints() {
            const getEarningPoints = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/earnpoints"
            );

            if (
                getEarningPoints &&
                getEarningPoints.data &&
                getEarningPoints.data.result
            ) {
                await this.$store.dispatch("setEarningPoints", getEarningPoints.data);
            } else {
                console.error(
                    "Ошибка поиска действий для получения баллов: " +
                        (getEarningPoints.data.message || "")
                );
                await this.$store.dispatch("clearEarningPoints");
            }
        },

        async updateDashboardGoal() {
            this.isLoadingGoalData = true;

            const getDashboardGoal = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/goal"
            );

            if (
                getDashboardGoal &&
                getDashboardGoal.data &&
                getDashboardGoal.data.result &&
                getDashboardGoal.data.response
            ) {
                await this.$store.dispatch(
                    "setDashboardGoal",
                    getDashboardGoal.data.response
                );
            } else {
                console.error(
                    "Ошибка выбранной цели: " + (getDashboardGoal.data.message || "")
                );
                await this.$store.dispatch("clearDashboardGoal");
            }
            this.isLoadingGoalData = false;
        },

        async updateDashboardShop() {
            const getDashboardShop = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/shop"
            );

            const itemsCosts =
                (this.$store.getters.getDashboardShop &&
                    this.$store.getters.getDashboardShop.shop &&
                    this.$store.getters.getDashboardShop.shop.studyOptions) ||
                [];
            this.minCostItem = Math.min.apply(
                null,
                itemsCosts.map(function(item) {
                    return item.points;
                })
            );

            if (
                getDashboardShop &&
                getDashboardShop.data &&
                getDashboardShop.data.result &&
                getDashboardShop.data.data
            ) {
                await this.$store.dispatch(
                    "setDashboardShop",
                    getDashboardShop.data.data
                );
            } else {
                console.error(
                    "Ошибка при поиске магазина: " + (getDashboardShop.data.message || "")
                );
                await this.$store.dispatch("clearDashboardShop");
            }
        },

        async updateDashboardAchievement() {
            this.isLoadingAchivementsData = true;

            const getDashboardAchievement = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/achievements"
            );

            if (
                getDashboardAchievement &&
                getDashboardAchievement.data &&
                getDashboardAchievement.data.result
            ) {
                await this.$store.dispatch(
                    "setDashboardAchievement",
                    getDashboardAchievement.data
                );
            } else {
                console.error(
                    "Ошибка при поиске достижений: " +
                        (getDashboardAchievement.data.message || "")
                );
                await this.$store.dispatch("clearDashboardAchievement");
            }

            this.isLoadingAchivementsData = false;
        },

        async checkIfFilledProfile() {
            const response = await this.axios.get("/profile/checkIfFilled");

            if (
                response &&
                response.data &&
                response.data.result &&
                response.data.isFilled
            ) {
                this.updateDashboardAchievement();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "dashboard.scss";
</style>

<style lang="scss">
@import "dashboard.scss";
</style>
