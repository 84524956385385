<template>
    <div class="popup-layout popup-layout--block">
        <div class="popup popup--3">
            <div class="earningPointsPopup">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h2 earningPointsPopup__title">
                    Набранные баллы
                </p>
                <div class="popup__scrollContent customScroll">
                    <div v-if="pointsTableLoaded">
                        <table
                            v-if="pointsStages && pointsStages.length"
                            class="text2 earningPointsPopup__table"
                        >
                            <tr class="colorFiord">
                                <th class="alignLeft">
                                    За что набрали
                                </th>
                                <th>
                                    Баллы
                                </th>
                            </tr>
                            <tr
                                v-for="(item, index) in pointsStages"
                                v-if="item.points > 0"
                                :key="item.name"
                                :item="item"
                            >
                                <td class="alignLeft">
                                    {{ item.name }}
                                </td>
                                <td>
                                <span class="boxText2 boxText2--sm boxText2--lightOrange"
                                >+{{ item.points }}</span
                                >
                                </td>
                            </tr>
                        </table>
                        <div v-else>
                            <p class="text">
                                Пока что Вы не заработали никаких баллов.
                                <br>
                                Начните <b><router-link :to="'/' + this.$route.params.slug + '/study'">обучение</router-link></b> чтобы заработать свои первые баллы.
                            </p>
                        </div>
                    </div>
                    <b-skeleton-table
                        v-else
                        :rows="6"
                        :columns="2"
                        :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                </div>
                <div class="earningPointsPopup__button">
                    <Button
                        title="закрыть"
                        extraClass="button"
                        :onClick="() => onClose()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "pointsPopup",
    components: { Button },
    props: ["onClose"],

    async beforeMount() {
        await this.getMemberPoints();
    },

    data() {
        return {
            pointsTableLoaded: false,
            pointsStages: []
        };
    },

    methods: {
        async getMemberPoints() {
            const getPointsProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/progress/v2"
            );

            if (getPointsProgress && getPointsProgress.data && getPointsProgress.data.result) {
                this.pointsStages = getPointsProgress.data.stages.filter(item => item.points);
                this.pointsTableLoaded = true;
            } else {
                console.error(
                    "Ошибка при поиске заработанных баллов участника: " + (getPointsProgress.data.error || "")
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "pointsPopup.scss";
</style>
