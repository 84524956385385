<template>
    <div class="popup-layout">
        <div class="popup popup--2">
            <div class="targetSelection">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h2 targetSelection__title">
                    Выбрать цель
                </p>

                <p>
                    Выберите одну программу, на которую Вы будете копить баллы и которая
                    будет Вас мотивировать.
                </p>
                <div class="targetSelection__contentItem">
                    <div class="radioClearGroup">
                        <label v-for="(item, index) in goal" :key="index">
                            <div
                                :class="
                                    `inputItem radioNoBorder
                                    ${
                                        item._id === selectedGoal._id
                                            ? ' radioNoBorder--success'
                                            : item._id === pickedItem._id
                                            ? ' radioNoBorder--picked'
                                            : ''
                                    }`
                                "
                            >
                                <div class="targetInput">
                                    <img
                                        :class="
                                            item._id === selectedGoal._id
                                                ? 'radioNoBorder__selectionIcon'
                                                : item._id === pickedItem._id
                                                ? 'hidden'
                                                : 'hidden'
                                        "
                                        alt="done"
                                        src="@/assets/img/pages/study/done.svg"
                                    />
                                    <input
                                        class="input--checkbox"
                                        type="radio"
                                        name="first"
                                        @click="() => handleClickButton(item._id)"
                                    />
                                    <div class="colorMirage targetInputText">
                                        <span> {{ item.name }}</span>
                                        <span
                                            :class="
                                                `text3 text3--extraBold ${
                                                    item._id === selectedGoal._id
                                                        ? 'colorGreen'
                                                        : item._id === pickedItem._id &&
                                                          'colorDenim'
                                                }`
                                            "
                                            >{{ item.points }} баллов</span
                                        >
                                    </div>
                                </div>
                                <a
                                    class="colorDenim targetInputLink"
                                    :href="`${item.detailsLink}`"
                                    target="_blank"
                                    rel="nofollow"
                                >
                                    Подробнее об этом продукте
                                </a>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="targetSelection__buttons">
                    <Button
                        title="отмена"
                        extraClass="button--white"
                        :onClick="() => onClose()"
                    />
                    <Button
                        title="выбрать"
                        extraClass="button"
                        :onClick="() => chooseGoal()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "targetSelection",
    components: { Button },
    props: ["onClose"],

    async beforeMount() {
        this.updateDashboardShop();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateDashboardShop();
        },
    },

    computed: {
        goal() {
            return this.$store.getters.getDashboardShop.shop.courseOptions[0].courses.filter(
                (item) =>
                    item.status === "active" &&
                    item._id !== "61716785ff5a2e84a4f9c2d4" &&
                    !item.isRequestSent
            );
        },
    },
    data() {
        return {
            selectedItem: {
                _id: "",
                name: "",
                points: "",
            },
            pickedItem: {},
            selectedGoal: {},
            dashbordGoal: this.$store.getters.getDashboardGoal.goal,
        };
    },

    methods: {
        async handleClickButton(id) {
            const { _id, name, points } = this.goal.find((item) => item._id === id);
            const selectedGoal = {
                _id,
                name,
                points,
            };
            this.pickedItem = this.goal.find((item) => item._id === id);
            this.selectedItem = selectedGoal;
        },

        chooseGoal: async function() {
            const response = await this.axios.post(
                `/programs/${this.$route.params.slug}/study/goal`,
                this.selectedItem
            );

            if (response && response.data && response.data.result) {
                this.onClose();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Вы выбрали цель",
                    },
                });
            } else {
                this.onClose();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Не удалось выбрать цель",
                    },
                });
            }

            const getDashboardGoal = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/goal"
            );

            if (
                getDashboardGoal &&
                getDashboardGoal.data &&
                getDashboardGoal.data.result &&
                getDashboardGoal.data.response
            ) {
                await this.$store.dispatch(
                    "setDashboardGoal",
                    getDashboardGoal.data.response
                );
            } else {
                console.error(
                    "Ошибка выбранной цели: " + (getDashboardGoal.data.message || "")
                );
                await this.$store.dispatch("clearDashboardGoal");
            }
        },

        async updateDashboardShop() {
            const getDashboardShop = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/shop"
            );

            if (
                getDashboardShop &&
                getDashboardShop.data &&
                getDashboardShop.data.result &&
                getDashboardShop.data.data
            ) {
                await this.$store.dispatch(
                    "setDashboardShop",
                    getDashboardShop.data.data
                );
            } else {
                console.error(
                    "Ошибка при поиске магазина: " + (getDashboardShop.data.message || "")
                );
                await this.$store.dispatch("clearDashboardShop");
            }
        },
    },

    mounted() {
        this.selectedGoal = this.goal.find((item) => item._id === this.dashbordGoal._id);
    },
};
</script>

<style lang="scss" scoped>
@import "targetSelection.scss";
</style>
